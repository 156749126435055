import "./App.css";
import { MetaplexProvider } from "./pages/MetaplexProvider";
import { useMemo, useState, useEffect } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl, Connection } from "@solana/web3.js";
import "@solana/wallet-adapter-react-ui/styles.css";
import Home from "./pages/Home";
import WL from "./pages/WL";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const [network, setNetwork] = useState("https://api.metaplex.solana.com/");

  // const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // const connection = new Connection("https://api.metaplex.solana.com/");

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
    ],
    [network]
  );

  return (
    <div>
      <ConnectionProvider endpoint={"https://api.metaplex.solana.com/"}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <MetaplexProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<WL />} />
                  <Route path="/wl" element={<WL />} />
                </Routes>
              </BrowserRouter>
            </MetaplexProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </div>
  );
}

export default App;
