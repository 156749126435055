import React, { useEffect, useState } from "react";
import { useMetaplex } from "./useMetaplex";
import { PublicKey } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import "../App.css";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

function WL() {
  const [nft, setNft] = useState(null);
  const [candyMachine, setCandyMachine] = useState();
  const [loaded, setLoaded] = useState(false);

  const { metaplex } = useMetaplex();
  const wallet = useWallet();

  const candyMachineAddress = new PublicKey(
    "5Hje4fWFkR4KZu66zMt98iDkMg447vqxj61qUvrd1G5w"
    // process.env.NEXT_PUBLIC_CANDY_MACHINE_ID
  );

  useEffect(() => {
    async function getCandyMachine() {
      let candyMachine = await metaplex
        .candyMachines()
        .findByAddress({ address: candyMachineAddress });
      setCandyMachine(candyMachine);
      setLoaded(true);
    }
    getCandyMachine();
  }, []);

  const mint = async () => {
    // let candyMachine = await metaplex
    //   .candyMachines()
    //   .findByAddress({ address: candyMachineAddress });
    // alert(candyMachine.itemsMinted);
    const { nft } = await metaplex.candyMachines().mint({
      candyMachine,
      //   collectionUpdateAuthority,
      collectionUpdateAuthority: candyMachine.authorityAddress,
    });
    setNft(nft);
  };

  return (
    <div className="h-screen text-white">
      <div className="absolute sm:block hidden top-[2vw] right-[2vw]">
        <WalletMultiButton className="connect_button" />
      </div>
      <div className="absolute top-[4vh] sm:left-[6vw] sm:translate-x-0 transform -translate-x-1/2 left-1/2">
        <p className="text-[#9fb94c] text-[6vw] font-black italic text_glow">
          SNOOP FROGGZ
        </p>
        <div className="absolute top-[2vw] -right-[4vw]">
          <a href="https://twitter.com/SnoopFrogz" className="text-[2.8vw]">
            <FaTwitter />
          </a>
          <a href="https://discord.gg/7hd3AbhrcX" className="text-[2.8vw]">
            <FaDiscord />
          </a>
        </div>
      </div>
      <img
        className="absolute border-2 img_glow sm:left-[20%] left-1/2 transform sm:-translate-x-1/4 -translate-x-1/2 sm:top-[28%] top-[15%] rounded-2xl sm:w-[30%] w-[75%]"
        src={require("../images/Snoop_froggz_1.png")}
        alt=""
      />
      <div className="absolute lg:top-[55%] sm:top-[45%] top-[75%] left-1/2 transform -translate-y-1/2 sm:-translate-x-0 -translate-x-1/2 sm:pr-[4vw]">
        <div>
          <p className="text-[4vw] font-black italic text-center text-[#9fb94c]">
            MINT <span className="text-white">YOUR</span>
          </p>
          <p className="text-[4vw] font-black italic text-center text-[#9fb94c] -mt-[2.2vw]">
            FROGG<span className="text-white">.</span>
          </p>
        </div>
        <p className="text-gray-400 font-medium sm:text-[1.1vw] text-[2vw] text-center py-[2vh]">
          Jump on the hype train with Snoop Froggz, the most exclusive
          meme-inspired NFT drop on the Solana blockchain! These 100 limited
          edition NFTs are not just a visual feast for the eyes but also a
          testament to the power of internet culture. Don't miss out on the
          opportunity to own a piece of digital art that's guaranteed to make
          waves. Get ready to ribbit with Snoop Froggz!
        </p>
        <div className="w-full">
          <div className="flex justify-center py-[1vh] text-[2vw] text-[#9fb94c]">
            {loaded
              ? "Minted: " + Number(candyMachine.itemsMinted) + "/100"
              : null}
          </div>
          <div className="flex justify-center pb-[2vh] text-[2vw] text-[#9fb94c]">
            FREE MINT!
          </div>
          {wallet.connected ? (
            <button
              onClick={mint}
              className="mx-auto mint_glow flex bg-[#97338f] px-8 py-4 rounded-xl text-[2vw]"
            >
              MINT
            </button>
          ) : (
            <WalletMultiButton className="mx-auto mint_glow flex bg-[#97338f] px-8 py-4 rounded-xl text-[2vw] connect_button" />
          )}
        </div>
      </div>
    </div>
  );
}

export default WL;
